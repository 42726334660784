import React from 'react';

const Game1 = () => {
    return (
        <div>
            <h3>FlapCat Christmas játék</h3>
            <iframe src="game_flapcat_christmas/game/gameplay/index.html" frameBorder="0"
                    className={'max-w-96 max-h-56 my-5 md:hidden'}/>
            <iframe src="game_flapcat_christmas/game/gameplay/index.html" width='750' height='414' frameBorder="0"
                    className={'max-w-screen-md max-h-96 my-5 hidden md:block'}/>
        </div>
    );
};

export default Game1;