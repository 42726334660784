import React, {useEffect, useState} from 'react';

const Box = ({date, image, setModal}) => {

    const [isBoxAvailable, setIsBoxAvailable] = useState('');

    const checkModalVisibility = () => {
        if (isBoxAvailable) {
            setModal({
                dayId: new Date(date).getDate().toString(),
                isVisible: true
            });
        }
    }

    useEffect(() => {
        if(process.env.REACT_APP_ENV === "prod") {
            setIsBoxAvailable(new Date().toLocaleDateString("hu-HU") >= new Date(date).toLocaleDateString("hu-HU"));
        } else {
            setIsBoxAvailable(new Date().toLocaleDateString("hu-HU") >= "2024. 11. 22.");
        }
    }, [date]);

    return (
        <>
            <div className={`relative z-30 max-w-[225px] md:max-w-none ${!isBoxAvailable ? 'grayscale ' : ''}`} onClick={checkModalVisibility}>
                <img src={image} alt=""/>
            </div>
        </>
    );
};

export default Box;
