import React, {useEffect, useState} from 'react';

const DesktopImage = ({date, setModal}) => {

    const [isBoxAvailable, setIsBoxAvailable] = useState('');

    const checkModalVisibility = () => {
        if (isBoxAvailable) {
            setModal({
                dayId: new Date(date.day).getDate().toString(),
                isVisible: true
            });
        }
    }

    useEffect(() => {
        if(process.env.REACT_APP_ENV === "prod") {
            setIsBoxAvailable(new Date().toLocaleDateString("hu-HU") >= new Date(date.day).toLocaleDateString("hu-HU"));
        } else {
            setIsBoxAvailable(new Date().toLocaleDateString("hu-HU") >= "2024. 11. 22.");
        }
    }, [date.day]);

    return (
        <>
            <div
                className={`relative ${!isBoxAvailable ? 'grayscale hover:animate-wiggle' : 'cursor-pointer hover:scale-105 transition-all'} `}
                onClick={checkModalVisibility}>
                <img src={date.image} alt=""/>
            </div>
        </>
    );
};

export default DesktopImage;
